import * as React from 'react';
import {useEffect} from 'react';
import {
  PAYBACK_ACTION_ACCOUNTBINDING,
  PAYBACK_ACTION_COOKIE,
  PAYBACK_CODE_COOKIE,
  PAYBACK_STATE_COOKIE,
  ROUTE_PAYBACK,
} from '../../../common/constants';
import {navigate} from 'gatsby';
import * as Cookies from 'js-cookie';

const Accountbinding: React.FC = () => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paybackCode = urlSearchParams.get('code') ?? undefined;
    if (paybackCode) {
      Cookies.set(PAYBACK_CODE_COOKIE, paybackCode);
    }
    const paybackState = urlSearchParams.get('state') ?? undefined;
    if (paybackState) {
      Cookies.set(PAYBACK_STATE_COOKIE, paybackState);
    }
    Cookies.set(PAYBACK_ACTION_COOKIE, PAYBACK_ACTION_ACCOUNTBINDING);
    navigate!(ROUTE_PAYBACK);
  }, []);

  return <></>;
};
export default Accountbinding;
